.view-related-products {
  &.view-display-id-block {
    .views-row {
      @include media($breakpoint-1) {
        @include span-columns(6 of 12);
        @include omega(2n);
      }

      @include media($breakpoint-3) {
        @include omega-reset(2);
        @include span-columns(3 of 12);
        @include omega(4n);
      }
      margin-bottom: flex-gutter($grid-columns);
    }
  }

  // Aandachtstrekkers.
  &.view-display-id-block_1 {
    .views-row {
      @include span-columns(6 of 12);
      @include omega(2n);
      @include media($breakpoint-1) {
        @include omega-reset(2);
        @include span-columns(4 of 12);
        @include omega(3n);
      }
      @include media($breakpoint-3) {
        @include omega-reset(3);
        @include span-columns(2 of 12);
        @include omega(6n);
      }
      margin-bottom: flex-gutter($grid-columns);
    }
  }
}