table {
  @include media($breakpoint-1) {
    display: table;
    font-size: 20px;
  }
  overflow-x: scroll;
  font-size: 16px;
  width: 100%;
  border-collapse: collapse;
}

thead {
  @include media($breakpoint-1) {
    font-size: 20px;
  }
  padding: 13px 20px;
  background: $primary-light;
  text-align: left;
  font-size: 16px;
  font-weight: $regular;
  text-transform: uppercase;
  th {
    color: $black;
    font-weight: $regular;
    padding: 7px;
  }
}

tbody {
  > tr {
    background: url('../img/line-dashed.svg') repeat-x bottom left;
  }
  td {
    padding: 30px 10px;
  }
}