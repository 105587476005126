.node-tip {
  $this: &;
  &.view-mode-teaser {
    .group-link {
      position: relative;
      display: block;
      text-decoration: none;
      color: $black;
      &:hover {
        .button {
          background: $black;
        }
      }
    }
    img {
      display: block;
      margin: 0 0 35px 0;
    }
    h2 {
      font-family: 'angleciap-bold', serif;
      text-transform: none;
      padding: 0;
    }
    .button {
      display: inline-block;
    }
    #{$this}__summary {
      margin: 0 0 24px 0;
    }
  }

  &.view-mode-full {
    .group-left {
      @include wysiwyg;
    }
  }
}