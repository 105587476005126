/// @group helpers
@mixin omega-reset($nth, $context: null) {
  @if $context == null {
    &:nth-child(#{$nth}n) {
      margin-right: flex-gutter();
    }
  } @else {
    &:nth-child(#{$nth}n) {
      margin-right: flex-gutter($context);
    }
  }
  &:nth-child(#{$nth}n+1) {
    clear: none;
  }
}

@mixin container {
  @include outer-container;
  padding-left: 15px;
  padding-right: 15px;
}

@mixin vertical-align {
  position: relative;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

@mixin wysiwyg {
  ul {
    margin: 0 0 10px 20px;
  }
}