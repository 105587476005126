.views-exposed-form {
  $filter-font-size: 20px;
  $filter-font-size-mobile: 16px;
  @include media($breakpoint-1) {
    font-size: $filter-font-size;
    margin-top: 0;
  }
  @include media($menu-switch) {
    margin: 0 0 35px 0;
  }
  font-size: $filter-font-size-mobile;
  font-weight: $bold;
  text-transform: uppercase;
  padding: 7px 0;
  position: relative;
  margin: 15px 0;
  a {
    color: $color;
    &:hover,
    &.active {
      color: $primary;
    }
  }
  .bef-select-as-links,
  .views-exposed-widget {
    display: inline-block;
  }
  .form-item {
    padding: 0;
    margin: 0;
    display: inline-block;
  }

  .form-checkboxes {
    .form-item {
      background: url('../img/line-dashed.svg') repeat-x bottom left;
    }
  }

  // Filtering in sidebar.
  .sidebar & {
    label {

    }
    .views-widget-sort-by,
    .views-widget-sort-order {
      display: none;
    }
    .views-exposed-widget {
      margin-bottom: 20px;
      width: 100%
    }
  }

  // Filtering in main region (sorting etc).
  .main & {
    background: url('../img/line-dashed.svg') repeat-x bottom left;
    label {
      @include media($breakpoint-1) {
        font-size: $filter-font-size;
      }
      font-size: $filter-font-size-mobile;
      position: static;
      padding: 0;
      display: inline-block;
    }
    #edit-field-pd-brand-tid-selective-wrapper,
    #edit-field-pd-price-value-1-wrapper,
    #edit-field-pd-price-value-wrapper {
      display: none;
    }
  }
}


