.price-block {
  $this: &;
  background: $primary;
  border-radius: 50%;
  width: 76px;
  height: 76px;
  line-height: 1.1;
  color: $white;
  font-size: 18px;
  font-weight: $bold;
  text-align: center;
  padding: 11px 6px;
  position: absolute;
  top: 10px;
  left: 10px;
  &__currency {
    font-weight: $light;
    font-size: 24px;
  }
  &__oldprice {
    font-size: 14px;
    position: relative;
    &::after {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      background: url('../img/cross.svg') no-repeat center;
      height: 100%;
      z-index: 1;
    }
  }
  // Sale modifier.
  &--sale {
    background: $black;
    padding-top: 8px;
    #{$this}__currency {
      font-size: 18px;
    }
    #{$this}__price {
      color: $primary;
    }
  }
}

.views-field-commerce-price {
  padding-right: 30px;
  text-align: right;
  .price-block {
    background: none;
    width: auto;
    height: auto;
    position: static;
    text-align: right;
    padding: 0;
    &__oldprice {
      font-weight: normal;
      &::after {
        background: url('../img/cross--black.svg') no-repeat center;
      }
    }
    div {
      @include media($breakpoint-1) {
        font-size: 20px;
      }
      font-size: 16px;
      color: $black;;
      display: inline-block;
    }
  }
}