$gbl-logo-color: $white;

@font-face {
  font-family: 'gbl-icons';
  src:  url('../fonts/gbl-icons/icomoon.eot?b4n57j');
  src:  url('../fonts/gbl-icons/icomoon.eot?b4n57j#iefix') format('embedded-opentype'),
  url('../fonts/gbl-icons/icomoon.woff2?b4n57j') format('woff2'),
  url('../fonts/gbl-icons/icomoon.ttf?b4n57j') format('truetype'),
  url('../fonts/gbl-icons/icomoon.woff?b4n57j') format('woff'),
  url('../fonts/gbl-icons/icomoon.svg?b4n57j#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
}

@mixin gbl-icons {
  // use !important to prevent issues with browser extensions that change fonts
  font-family: 'gbl-icons' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.gbl-logo {
  transition: all 100ms linear;
  position: relative;
  color: $gbl-logo-color;
  display: inline-block;
  width: 51px;
  margin-left: 10px;
  top: 5px;
  &:focus,
  &:hover {
    width: 140px;
    color: $gbl-logo-color;
    .gbl-logo__by {
      opacity: 1;
      right: 55px;
      visibility: visible;
    }
    .gbl-logo__logo {
      &::before {
        opacity: 0;
      }
      &::after {
        opacity: 1;
      }
    }
  }
}

.gbl-logo__by {
  transition: all 100ms linear;
  font-size: 0;
  position: absolute;
  right: 10px;
  visibility: hidden;
  width: 73px;
  &::before {
    @include gbl-icons;
    content: '\e905';
    font-size: 14px;
  }
}

.gbl-logo__logo {
  font-size: 0;
  position: relative;
  display: block;
  &::before,
  &::after {
    @include gbl-icons;
    transition: all 100ms linear;
    background: none;
    position: absolute;
    right: 0;
    display: inline-block;
    font-size: 18px;
  }
  &::before {
    opacity: 1;
    content: '\e901';
  }
  &::after {
    opacity: 0;
    content: '\e900';
  }
}
