$hamburger-color: #000;
$hamburger-width: 24px;
$hamburger-height: 18px;
$hamburger-line-height: 2px;
$hamburger-line-border-radius: 2px;

.hamburger {
  width: $hamburger-width;
  height: $hamburger-height;
  position: relative;
  transform: rotate(0deg);
  transition: .5s ease-in-out;
  cursor: pointer;
  margin: 4px 0 0 0;
}
.hamburger__line {
  display: block;
  position: absolute;
  height: $hamburger-line-height;
  width: 100%;
  background: $hamburger-color;
  opacity: 1;
  left: 0;
  transform: rotate(0deg);
  transition: .25s ease-in-out;
  border-radius: $hamburger-line-border-radius;
  &:nth-child(1) {
    top: 0;
    .mobile--open & {
      top: $hamburger-height / 2 - ($hamburger-line-height / 2);
      width: 0;
      left: 50%;
    }
  }
  &:nth-child(2) {
    top: $hamburger-height / 2 - ($hamburger-line-height / 2);
    .mobile--open & {
      transform: rotate(45deg);
    }
  }
  &:nth-child(3) {
    top: $hamburger-height / 2 - ($hamburger-line-height / 2);
    .mobile--open & {
      transform: rotate(-45deg);
    }
  }
  &:nth-child(4) {
    top: $hamburger-height - $hamburger-line-height;
    .mobile--open & {
      top: $hamburger-height / 2 - ($hamburger-line-height / 2);
      width: 0;
      left: 50%;
    }
  }
}
