.commerce-add-to-cart {
  .form-type-select {
    @include clearfix;
    margin: 0 0 18px 0;
    background: url('../img/line-dashed.svg') repeat-x bottom left;
    label {
      font-size: 20px;
      position: static;
      padding: 0;
      font-weight: $bold;
      display: inline;
    }
    select {
      font-size: 20px;
      border: none;
      float: right;
      width: auto;
      padding-right: 60px;
    }
  }
  .form-submit {
    background-image: url('../img/icon-cart.svg');
    background-repeat: no-repeat;
    background-position: left 20px top 8px;
    padding: 11px 40px 10px 60px;
  }
}