.subcontent {
  @include clearfix;
  .block {
    @include media($breakpoint-3) {
      @include span-columns(6 of 12);
    }
  }
  .block:nth-child(2) {
    @include media($breakpoint-3) {
      @include omega;
      float: right;
    }
  }
  .block:last-child {
    @include media($breakpoint-3) {
      @include span-columns(12 of 12);
      @include omega;
    }
  }
  .block-views {
    h2 {
      background: $black;
      color: $white;
      padding: 13px 20px;
      margin: 0 0 40px 0;
    }
  }
}