.block-commerce-checkout-progress {
  position: relative;
  &::before {
    content: '';
    border-bottom: 1px solid $grey-blue;
    display: block;
    width: 100%;
    position: absolute;
    top: 24px;
    left: 0;
    z-index: -1;
  }
  ol.commerce-checkout-progress {
    @include media($breakpoint-2) {
      display: block;
      padding: 0;
      margin: 0 0 50px 0;
    }
    display: none;
    &.checkout-pages-6  {
      li {
        @include media($breakpoint-2) {
          width: 16.666666667%;
        }
      }
    }
    li {
      @include media($breakpoint-2) {
        width: 100%;
        color: $grey;
        font-weight: $bold;
        list-style: none;
        text-align: center;
      }
      &::before {
        @include icons;
        background: $grey;
        color: $white;
        content: '';
        width: 48px;
        height: 48px;
        display: block;
        border-radius: 50%;
        margin: 0 auto 5px auto;
        font-size: 28px;
        padding: 10px 0 0 0;
      }
      &.active {
        color: $color;
        display: block;
        &::before {
          background: $primary;
        }
      }
      &.account {
        &::before {
          @extend .icon-profile:before;
          font-size: 22px;
          padding-top: 12px;
        }
      }
      &.checkout {
        &::before {
          @extend .icon-payment:before;
        }
      }
      &.shipping {
        &::before {
          @extend .icon-shipping:before;
          font-size: 18px;
          padding-top: 15px;
          padding-right: 2px;
        }
      }
      &.review {
        &::before {
          @extend .icon-review:before;
          font-size: 18px;
          padding-top: 15px;
        }
      }
      &.payment {
        &::before {
          @extend .icon-paying:before;
          font-size: 20px;
          padding-top: 14px;
        }
      }
      &.complete {
        &::before {
          @extend .icon-completed:before;
          padding-top: 8px;
        }
      }
    }
  }
}