.footer {
  @include media($breakpoint-2) {
    text-align: left;
  }
  @include clearfix;
  background: $black;
  color: $white;
  font-weight: $light;
  padding: 40px 30px 30px 30px;
  text-align: center;
  a {
    color: $white;
  }
  .block {
    @include media($breakpoint-2) {
      @include span-columns(3 of 12);
      @include omega(4n);
      margin-bottom: 0;
    }
    margin-bottom: 20px;
    &:last-child {
      @include media($breakpoint-2) {
        text-align: right;
      }
    }
  }
  h2 {
    color: $white;
    font-family: $base-font-family;
    font-size: 14px;
    font-weight: $semibold;
    text-transform: uppercase;
  }
  ul.menu {
    li {
      list-style: none;
      a {
        text-decoration: none;
        &:hover {
          border-bottom: 1px solid $white;
        }
      }
    }
  }
  // Form in footer.
  p,
  .form-item {
    margin: 0 0 10px 0;
  }
  .form-text {
    color: $white;
    &:focus {
      border: 1px solid $white;
    }
  }
  .form-actions {
    @include media($breakpoint-2) {
      text-align: left;
    }
  }
  .form-submit {
    @include button--small;
  }
}