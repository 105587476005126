.primary-nav {
  $breakpoint-2: 930px;
  @include media($menu-switch) {
    text-align: center;
    margin: 26px 0 26px 0;
    display: block;
  }
  @include media($breakpoint-2) {
    text-align: right;
  }
  display: none;
  ul.menu {
    li {
      @include media($breakpoint-2) {
        margin: 0 0 0 20px;
      }
      display: inline-block;
      a {
        @include media($breakpoint-2) {
          font-size: 22px;
        }
        font-family: 'angleciap-bold';
        font-size: 18px;
        background: $black;
        color: $white;
        text-decoration: none;
        padding: 5px 17px;
        &.active-trail,
        &.active,
        &:hover {
          background: $primary;
        }
      }
    }
  }
}