.view-commerce-cart-summary,
.view-commerce-cart-form {
  .views-field-field-p-image {
    @include media($breakpoint-1) {
      width: 20px;
      display: table-cell;
    }
    @include media($breakpoint-3) {
      width: 225px;
    }
    display: none;
    img {
      @include media($breakpoint-3) {
        display: block;
      }
      display: none;
    }
  }
  .views-field-edit-quantity {
    @include media($breakpoint-1) {
      width: 70px;
    }
  }
  .views-field-commerce-total {
    text-align: right;
    padding-right: 30px;
  }
  .views-field-commerce-unit-price {
    @include media($breakpoint-1) {
      display: table-cell;
    }
    display: none;
  }
  .form-text {
    height: 30px;
    width: 60px;
  }
  .short-desc {
    @include media($breakpoint-1) {
      display: block;
    }
    display: none;
  }
}


.commerce-price-formatted-components {
  @include media($breakpoint-1) {
    width: 40% !important;
  }
  display: table !important;
  width: 100% !important;
  margin: 0 0 30px;
  .component-total {
    padding-right: 30px;
  }
}

.views-field-line-item-title {
  a {
    text-decoration: none;
    color: $color;
  }
  .short-desc {
    font-size: 14px;
    p {
      margin: 0;
    }
  }
}
.views-field-commerce-price {
  position: relative;
}

.views-field-edit-quantity {
  .form-item {
    margin: 0;
  }
}