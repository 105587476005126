.bean-promo-banner {
  $this: &;
  @include media($breakpoint-3) {
    margin: 0 0 29px 0;
  }
  text-align: center;
  position: relative;
  margin: 0 0 15px 0;
  overflow: hidden;

  &__bg {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-size: cover;
    z-index: -1;
    transition: 300ms all linear;
    transform: scale(1);
  }

  &__link {
    height: 333px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 20px;
    &:hover {
      #{$this}__text {
        opacity: 1 !important;
      }
    }
  }

  &__text {
    background: $primary;
    color: $white;
    font-size: 30px;
    font-family: 'angleciap-bold';
    padding: 5px 20px 4px;
    transition: 200ms all linear;
  }

  &:hover {
    #{$this}__bg {
      transform: scale(1.05);
    }
  }

  &.view-mode-default {
    #{$this}__text {
      @include media($breakpoint-3) {
        opacity: 0;
      }
    }
  }

  &.view-mode-larger {
    #{$this}__link {
      @include media($breakpoint-3) {
        height: 695px;
      }
    }
  }
}