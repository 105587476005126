.node-type-product-display {
  .main {
    .block-views {
      > h2 {
        background: $grey-middle;
        color: $white;
        padding: 13px 20px;
        margin: 0 0 40px 0;
      }
    }
  }
}