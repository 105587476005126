.vocabulary-categorie {
  &.view-mode-as_tip {
    @include media($breakpoint-1) {
      display: block;
      padding-bottom: 35px;
      border-bottom: 1px solid $grey-blue;
    }
    @include media($breakpoint-5) {
      display: flex;
    }
    display: none;
    img  {
      @include media($breakpoint-5) {
        display: block;
      }
      display: none;
    }
    .group-left {
      width: 100%;
      @include media($breakpoint-5) {
        @include span-columns(4 of 9);
      }
    }
    .group-right {
      width: 100%;
      @include media($breakpoint-5) {
        @include span-columns(5 of 9);
        @include omega;
        display: flex;
        flex-direction: column;
      }
      img {
        margin-bottom: 35px;
      }
    }
    .group-link {
      @include media($breakpoint-5) {
        padding: 0 100px;
      }
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      text-align: center;
      background: $primary;
      color: $white;
      flex-grow: 1;
      text-decoration: none;
      font-size: 24px;
      line-height: 1.15;
      padding: 20px;
      &:hover {
        background: $black;
      }
      h2 {
        font-family: 'angleciap-bold';
        color: $white;
        margin: 0 0 5px 0;
        padding: 0;
      }
    }
  }
}