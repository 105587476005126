.field-dotted {
  $this: &;
  @mixin dotted {
    padding: 0 0 14px 0;
    margin: 0 0 18px 0;
    background: url('../img/line-dashed.svg') repeat-x bottom left;
  }
  @include clearfix;
  @include dotted;
  &__value {
    float: right;
    font-size: 20px;
  }
  &.field-dotted--above {
    background: none;
    padding: 0;
    margin: 0;
    .label-above {
      @include dotted;
    }
  }
}