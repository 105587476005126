.search-bar {
  @include media($menu-switch) {
    text-align: right;
  }
  display: none;
  max-height: 42px;
  @mixin placeholder-layout {
    color: $white;
    font-weight: $bold;
    font-size: 18px;
  }
  background: $primary;
  .container {
    position: relative;
  }
  label {
    display: none;
  }
  .views-exposed-form {
    margin: 0;
    padding: 0;
  }
  .form-item {
    margin: 0;
  }
  .form-text {
    @include placeholder-layout;
    @include media($menu-switch) {
      text-align: right;
    }
    background: none;
    border: 0;
    max-height: 42px;
    text-decoration: none;
    padding-right: 0;

  }
  .form-text:-ms-input-placeholder {
    @include placeholder-layout;
  }
  .form-text::-moz-placeholder {
    @include placeholder-layout;
  }
  .form-text:-moz-placeholder {
    @include placeholder-layout;
  }
  .form-text::-webkit-input-placeholder {
    @include placeholder-layout;
  }
  .form-submit {
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    opacity: 0;
    width: 1px;
  }
}