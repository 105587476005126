.line-item-summary {
  font-size: 20px;
  padding: 40px 30px;
  background: url('../img/line-dashed.svg') repeat-x bottom left;
  .line-item-total-label {
    text-transform: uppercase;
  }
  .line-item-total-raw {
    display: inline-block;
    width: 250px;
  }
}