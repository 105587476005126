@mixin button {
  transition: all 150ms linear;
  appearance: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: $white;
  border-radius: 0;
  color: $base-link-color;
  padding: 6px 10px 5px 10px;
  text-shadow: none;
  box-shadow: none;
  border: 0;
  text-decoration: none;
  font-weight: $semibold;
  font-size: 20px;
  cursor: pointer;
  &:hover,
  &:focus {
    color: $white;
    outline: none;
  }
}
.button {
  @include button;
}

@mixin button--primary {
  background-color: $primary;
  color: $white;
  &:hover {
    background-color: $black;
  }
}
.button--primary {
  @include button--primary;
}

@mixin button--secundary {
  background: $secundary;
  color: $white;
}
.button--secundary {
  @include button--secundary;
}

@mixin button--small {
  font-size: 14px;
}

.button--small {
  @include button--small;
}