.prefooter {
  .container {
    @include media($breakpoint-2) {
      display: flex;
    }
  }
  .block {
    @include media($breakpoint-2) {
      @include span-columns(4 of 12);
      @include omega(3n);
    }
    margin-bottom: flex-gutter($grid-columns);
    > .entity {
      height: 100%;
    }
  }
}