@font-face {
  font-family: 'icomoon';
  src:  url('../fonts/icomoon/icomoon.eot?uc2dffe');
  src:  url('../fonts/icomoon/icomoon.eot?uc2dffe#iefix') format('embedded-opentype'),
  url('../fonts/icomoon/icomoon.woff2?uc2dffe') format('woff2'),
  url('../fonts/icomoon/icomoon.ttf?uc2dffe') format('truetype'),
  url('../fonts/icomoon/icomoon.woff?uc2dffe') format('woff'),
  url('../fonts/icomoon/icomoon.svg?uc2dffe#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
}

@mixin icons {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

[class^="icon-"],
[class*=" icon-"] {
  &::before {
    @include icons;
  }
}

.icon-logout:before {
  content: "\e90d";
}
.icon-payment:before {
  content: "\e90c";
}
.icon-completed:before {
  content: "\e909";
}
.icon-paying:before {
  content: "\e90a";
}
.icon-review:before {
  content: "\e90b";
}
.icon-instagram:before {
  content: "\e907";
}
.icon-pinterest:before {
  content: "\e908";
}
.icon-cart:before {
  content: "\e900";
}
.icon-facebook:before {
  content: "\e901";
}
.icon-profile:before {
  content: "\e902";
}
.icon-search:before {
  content: "\e903";
}
.icon-service:before {
  content: "\e904";
}
.icon-shipping:before {
  content: "\e905";
}
.icon-time:before {
  content: "\e906";
}
