.checkout-buttons  {
  background: url('../img/line-dashed.svg') repeat-x top left;
  text-align: right;
  padding: 25px 0;
  margin: 50px 0;
  .checkout-continue {
    float: right;
    margin-left: 20px;
  }
  .button-operator {
    display: none;
  }
}

.checkout-back,
.checkout-cancel {
  color: $color !important;
  text-decoration: none;
  font-family: $base-font-family;
  padding: 6px 10px 5px 10px;
  font-weight: $regular;
  &::before {
    content: '‹ ';
  }
}

.delete-line-item  {
  font-size: 0 !important;
  background: url('../img/icon-close.svg') center center $grey-middle no-repeat;
  width: 30px;
  height: 30px;
  display: block;
}

.commerce-line-item-actions {
  margin: 0 0 70px 0;
  .form-submit {
    @include media($breakpoint-1) {
      margin: 0 0 0 20px;
    }
    margin: 0 0 10px 20px;
  }
}



// Smaller wrappers.
.commerce_shipping,
.customer_profile_shipping,
.customer_profile_billing,
.account {
  .fieldset-wrapper {
    max-width: 690px;
    margin: 0 auto;
  }
}

.addressfield-container-inline {
  @include clearfix;
  .form-item {
    @include media($breakpoint-1) {
      margin-right: 2%;
      float: left;
    }
    &:last-child {
      margin-right: 0;
      width: 100%;
    }
  }
}


.addressfield-container-inline.name-block {
  .form-item {
    @include media($breakpoint-1) {
      width: 49%;
    }
  }
}

.addressfield-container-inline.street-block {
  .form-item {
    @include media($breakpoint-1) {
      width: 19%;
    }
    &:first-child {
      @include media($breakpoint-1) {
        width: 58%;
      }
    }
  }
}

.addressfield-container-inline.locality-block {
  .form-item {
    @include media($breakpoint-1) {
      width: 54%;
    }
    &:first-child {
      @include media($breakpoint-1) {
        width: 44%;
      }
    }
  }
}

.account_form,
.checkout-review {
  margin: 0 0 20px 0;
  font-size: 20px;
  label {
    position: static;
    font-size: 20px;
    padding: 0;
    &::after {
      content: ': ';
      margin-left: -3px;
    }
  }
}

.checkout-completion-message,
.checkout-help {
  text-align: center;
  padding: 20px;
  background: $grey-light;
}

.checkout-completion-message {
  margin: 0 0 30px 0;
}