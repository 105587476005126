.bean-info-block {
  @include media($breakpoint-4) {
    padding: 30px 40px;
  }
  padding: 20px;
  color: $black;
  border: 1px solid $black;
  text-align: center;
  font-weight: $light;
  .icon {
    &::before {
      font-size: 36px;
    }
    margin: 0 0 10px 0;
  }
  h3 {
    margin: 0;
  }
  p {
    margin: 0;
  }
}