.messages {
  border: 1px solid #bce8f1;
  color: #31708f;
  background-color: #d9edf7;
  padding: 15px;
  margin-bottom: 20px;
  border-radius: 4px;
  ul {
    padding-left: 20px;
  }
  &.error {
    color: #a94442;
    background-color: #f2dede;
    border-color: #ebccd1;
  }
}