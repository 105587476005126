.pane-views-bestseller-block {
  .pane-title {
    @include media($menu-switch) {
      text-align: left;
    }
    text-align: center;
    border-bottom: 1px solid $grey-blue;
  }
  .view-content {
    @include media($breakpoint-1) {
      display: flex;
      flex-wrap: wrap;
    }
  }
  .views-row {
    @include media($breakpoint-1) {
      @include span-columns(6 of 12);
      @include omega(2n);
    }

    @include media($breakpoint-3) {
      @include omega-reset(2);
      @include span-columns(3 of 12);
      @include omega(4n);
    }
    margin-bottom: flex-gutter($grid-columns);
  }
}