button,
input,
select,
textarea {
  font-family: $base-font-family;
}

.form-actions {
  text-align: center;
}

.form-submit {
  @include button;
  @include button--primary;
}

.form-item {
  margin: 0 0 25px 0;
  position: relative;
  .description {
    font-size: 12px;
    margin: 4px 0;
  }
  label {
    background: $white;
    position: absolute;
    left: 5px;
    top: -11px;
    padding: 0 10px;
    font-size: 14px;
  }
}

select,
textarea,
.form-email,
.form-text {
  color: $color;
  font-size: 14px;
  line-height: 1;
  background: none;
  transition: all 100ms linear;
  appearance: none;
  font-family: $base-font-family;;
  box-shadow: none;
  padding: 10px 15px;
  border: 1px solid $grey-middle;
  max-width: 100%;
  width: 100%;
  border-radius: 0;
  &.error {
    border: 1px solid $error;
  }
  &:focus {
    outline: none;
    color: $black;
    border: 1px solid $black;
  }
}

select {
  background: url('../img/select.svg') no-repeat right 10px center;
  padding-right: 25px;
}

.form-type-bef-checkbox,
.form-type-checkbox,
.form-type-radio {
  input {
    opacity: 0;
    position: absolute;
  }
  label {
    position: relative;
    padding-left: 30px;
    left: 0;
    top: 0;
    &::before {
      transition: all 200ms ease;
      position: absolute;
      content: '';
      width: 18px;
      height: 18px;
      border: 1px solid $grey-middle;
      top: 2px;
      left: 0;
    }
  }
  input:checked + label::before {
    background: $black;
    box-shadow: inset 0 0 0 2px $white;
  }
}
.form-type-radio label::before {
  border-radius: 50%;
}
.webform-component-checkboxes {
  > label {
    padding: 0;
    position: static;
  }
}
.form-checkboxes {
  .form-item {
    display: block !important;
    padding: 10px 0 !important;
    label {
      font-weight: $regular;
      font-size: 18px;
      text-transform: none;
    }
  }
}
.form-type-select {
  width: 100%;
}

// Placeholder layout
@mixin placeholder-layout {

}
.form-text:-ms-input-placeholder {
  @include placeholder-layout;
}
.form-text::-moz-placeholder {
  @include placeholder-layout;
}
.form-text:-moz-placeholder {
  @include placeholder-layout;
}
.form-text::-webkit-input-placeholder {
  @include placeholder-layout;
}
textarea:-ms-input-placeholder {
  @include placeholder-layout;
}
textarea::-moz-placeholder {
  @include placeholder-layout;
}
textarea:-moz-placeholder {
  @include placeholder-layout;
}
textarea::-webkit-input-placeholder {
  @include placeholder-layout;
}

// Hide ugly grippie functionality.
.grippie {
  display: none;
}


fieldset {
  display: block;
  padding: 0;
  margin: 0;
  border: 0;
  legend {
    font-family: $base-font-family;
    font-size: 16px;
    text-transform: uppercase;
    color: $black;
    display: block;
    padding: 13px 20px;
    margin: 0 0 40px 0;
    background: $primary-light;
    font-weight: $bold;
    width: 100%;
    text-align: center;
  }
}