.view-promotions {
  .views-row {
    @include media($breakpoint-1) {
      @include span-columns(6 of 12);
      @include omega(2n);
    }

    @include media($breakpoint-3) {
      @include omega-reset(2, 9);
      @include span-columns(3 of 9);
      @include omega(3n);
    }
    margin-bottom: flex-gutter($grid-columns);
  }
}