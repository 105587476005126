.container {
  @include container;
}

.lt-ie9 .container {
  width: $max-width;
}

.sidebar-first {
  .sidebar {
    @include media($breakpoint-2) {
      @include span-columns(3 of 12);
    }
  }
  .main {
    @include media($breakpoint-2) {
      @include span-columns(9 of 12);
      @include omega;
    }
  }
}

.content {
  @include media($breakpoint-2) {
    margin: 30px 0;
  }
  margin: 20px 0;
}