// Basic fade in.
.onscroll {
  @include clearfix;
  transition: all 1500ms linear;
  opacity: 0;
  visibility: hidden;
  &.is-visible {
    opacity: 1;
    visibility: visible;
  }
}


// Fade in from bottom to top.
.onscroll--bottom {
  transform: translateY(50%);
  transition: transform 1.5s cubic-bezier(.19, 1, .22, 1), opacity 700ms linear;
  &.is-visible {
    transform: translateY(0);
  }
}

// Fade in from left to right.
.onscroll--left {
  transform: translate(-200px);
  transition: transform 1.5s cubic-bezier(.19, 1, .22, 1), opacity 700ms linear;
  &.is-visible {
    transform: translate(0);
  }
}

// Fade in from right to left.
.onscroll--right {
  transform: translate(200px);
  transition: transform 1.5s cubic-bezier(.19, 1, .22, 1), opacity 700ms linear;
  &.is-visible {
    transform: translate(0);
  }
}

