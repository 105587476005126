ul.tabs {
  margin: 0 0 25px 0;
  padding: 0;

  li {
    display: inline-block;
    margin: 0 0 10px 0;
    a {
      @include button;
      @include button--primary;
      display: block;
      &:hover {
        @include button--primary;
      }

    }

  }

}
