.mobile--open {
  overflow: hidden;
}

.mobile-nav__toggler {
  @include media($menu-switch) {
    display: none;
  }
  float: right;
  font-size: 0;
}

.mobile-nav {
  background: $primary;
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  padding: 160px 15px 30px 25px;
  overflow: auto;
  opacity: 0;
  z-index: -1;
  visibility: hidden;
  .mobile--open & {
    opacity: 1;
    z-index: 15;
    visibility: visible;
  }
  ul.menu {
    text-align: center;
    li {
      display: block;
      a {
        color: $black;
        display: block;
        text-decoration: none;
        padding: 5px 0;
      }
    }
  }
  .main-menu {
    margin: 0 0 35px 0;
    ul.menu {
      li {
        a {
          font-family: 'angleciap-bold';
          font-size: 18px;
          padding: 10px 0;
        }
      }
    }
  }
  .menu-social {
    color: $white;
    text-align: center;
    margin: 55px 0 0 0;
    h2 {
      text-transform: uppercase;
      font-size: 14px;
      font-weight: normal;
      font-family: $base-font-family;
    }
  }
}
