.node-product-display {
  &.view-mode-teaser_small {
    height: 100%;
    .node__link,
    div {
      height: 100%;
    }
    .field-type-image .field-item {
      @include media($breakpoint-1) {
        display: flex;
        align-items: center;
      }
    }
  }
  &.node-teaser {
    text-align: center;
    font-weight: $light;
    height: 100%;
    .node__link {
      height: 100%;
    }
    img {
      margin: 0 0 10px 0;
    }
    h3 {
      padding: 0 10px;
      &::after {
        content: '';
        border-bottom: 1px solid $grey-blue;
        display: block;
        width: 40px;
        margin: 7px auto 0 auto;
      }
    }
    p {
      margin: 0;
    }
    .node__brand {
      color: $secundary;
    }
  }

  // Detail.
  &.view-mode-full {
    img {
      display: block;
    }
    .commerce-product-extra-field {
      float: right;
      .commerce-product-sku-label {
        display: none;
      }
      .commerce-product-sku {
        font-weight: $light;
        color: $color;
      }
    }
    .group-content {
      @include clearfix;
      @include media($breakpoint-3) {
        display: flex;
      }
      border: 1px solid $black;
      margin: 0 0 45px 0;
    }
    .group-left {
      @include media($breakpoint-3) {
        @include span-columns(7 of 12);
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
    .group-right {
      @include media($breakpoint-3) {
        @include span-columns(5 of 12);
        @include omega;
        padding: 20px 25px 45px 0;
      }
      padding: 25px;
      @include wysiwyg;
    }
    .price {
      color: $primary-middle;
      font-size: 20px;
      font-weight: $bold;
      border-bottom: 1px solid $grey-blue;
      margin: 0 0 30px 0;
      padding: 0 0 10px 0;
      .commerce-product-field {
        display: inline-block;
      }
      .commerce-product-field-field-commerce-saleprice + .commerce-product-field-commerce-price {
        color: $black;
        float: left;
        text-decoration: line-through;
        margin-right: 10px;
      }
      .commerce-product-field-field-commerce-saleprice.commerce-product-field-empty + .commerce-product-field-commerce-price {
        color: $primary-middle;
        float: none;
        text-decoration: none;
      }
    }
  }
}