.logo {
  img,
  a {
    display: block;
  }
  img {
    @include media($menu-switch) {
      max-width: 100%;
    }
    max-width: 240px;
    margin: 0 auto;
  }
  .svg & {
    background: url('../logo.png') no-repeat center center;
    background-size: contain;
    img {
      opacity: 0;
    }
  }
}