.pager {
  text-align: center;
  margin: 30px 0 60px 0;
  font-size: 14px;
  color: $black;
  li {
    display: inline-block;
    margin: 0 10px;
    &.pager-current {
      font-weight: $bold;
    }
    a {
      color: $black;
      text-transform: uppercase;
      text-decoration: none;
    }
  }
}