.header {
  padding: 20px 0 0;
  position: relative;
  z-index: 20;
  background: $white;
  .mobile--open & {
    position: fixed;
    width: 100%;
    top: 0;
    left: 0;
  }
   .logo {
     @include media($menu-switch) {
       float: left;
       max-width: 273px;
     }
     text-align: center;
   }
  .block {
    @include media($menu-switch) {
      float: right;
    }
    float: left;
  }
  &__inner {
    @include media($menu-switch) {
      background: none;
      margin: 0;
      padding: 0;
    }
    @include clearfix;
    background: $grey;
    margin: 20px -15px 0 -15px;
    padding: 10px 15px;
  }
  .block-menu {
    @include media($menu-switch) {
      position: relative;
      top: 22px;
      margin-right: 8px;
      font-size: 14px;
      display: block;
      ul.menu {
        li {
          display: inline-block;
          a {
            color: $color;
            text-decoration: none;
          }
        }
      }
    }
    display: none;
  }
}