.block-cta {
  @include media($menu-switch) {
    margin: 17px 0 0 0;
  }
  &__link {
    @include media($menu-switch) {
      padding: 7px 0;
      width: 35px;
      height: 35px;
    }
    color: $white;
    display: block;
    background: $primary;
    width: 25px;
    height: 25px;
    float: left;
    font-size: 0;
    text-decoration: none;
    text-align: center;
    padding: 5px 0;
    border-radius: 50%;
    margin: 0 0 0 8px;
    position: relative;
    &::before {
      @include media($menu-switch) {
        font-size: 18px;
      }
      font-size: 15px;
    }
    &:hover {
      color: $white;
      background: $secundary;
    }
  }
  .quantity {
    position: absolute;
    background: black;
    display: block;
    text-align: center;
    top: -7px;
    right: -7px;
    width: 22px;
    height: 22px;
    border-radius: 50%;
    font-size: 13px;
    padding: 3px 0 0 0;
  }
}