@font-face {
  font-family: 'angleciap-light';
  src: url('../fonts/anglecia/anglecia_pro_display_w01_light-webfont.woff2') format('woff2'),
  url('../fonts/anglecia/anglecia_pro_display_w01_light-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'angleciap-bold';
  src: url('../fonts/anglecia/anglecia_pro_title_w01_bold-webfont.woff2') format('woff2'),
  url('../fonts/anglecia/anglecia_pro_title_w01_bold-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

// Add your site-variables, such as colours and font sizes in this file.
$legacy-support-for-ie: false;
$normalize-headings: true;

// Colors
$background: #fff;
$color: #4A4A4A;



$black: #000;
$grey-middle: #979797;
$grey-blue: #A5AFB4;
$grey: #EBEBEB;
$grey-light: #F5F5F5;
$white: #fff;


$primary: #60CED0;
$primary-middle: #73CDD2;
$primary-light: #B3E7E7;
$secundary: #000;

$error: #a94442;

$base-text-color: $color;
$base-selected-text-color: invert($base-text-color);
$base-selected-text-background-color: $base-text-color;
$base-link-color: $primary;
$base-link-hover-color: $primary;

// Fonts
$base-font-family:  "proxima-nova", Helvetica, Arial, sans-serif;
$title-font-family:  "angleciap-bold", serif;

$base-font-size: 16px;
$base-line-height: 1.375;

$thin: 100;
$light: 300;
$regular: 400;
$medium: 500;
$semibold: 600;
$bold: 700;

// Breakpoints
$breakpoint-1: 560px;
$breakpoint-2: 720px;
$breakpoint-3: 880px;
$breakpoint-4: 960px;
$breakpoint-5: 1024px;

$menu-switch: 720px;

// Grid settings (Powered by Bourbon Neat).
// Source: http://thoughtbot.github.io/neat-docs/latest/#variable

// Sets the relative width of a single grid column. The unit used should be the same one used to define `$gutter`.
$column: 65;

// Sets the relative width of a single grid gutter. The unit used should be the same one used to define `$column`.
$gutter: 30;

// Sets the total number of columns in the grid. Its value can be overridden inside a media query using the `media()` mixin.
$grid-columns: 12;

// Sets the max-width property of the element that includes `outer-container()`.
$max-width: 1140px;

// When set to true, it sets the box-sizing property of all elements to `border-box`.
$border-box-sizing: true;

// Sets the default layout direction of the grid. Can be `LTR` or `RTL`.
$default-layout-direction: LTR;

// Displays the visual grid when set to true.
// The overlaid grid may be few pixels off depending on the browser's rendering engine and pixel rounding algorithm.
$visual-grid: false;

// Sets the visual grid color.
$visual-grid-color: #eee;

// Sets the 'z-index' property of the visual grid. Can be `back` (behind content) or `front` (in front of content).
$visual-grid-index: back;

// Sets the opacity property of the visual grid.
$visual-grid-opacity: .4;
