.views-widget-sort-order {
  position: absolute;
  right: 10px;
  top: -5px;
  label {
    display: none !important;
  }
  .active {
    display: none !important;
  }
  .form-type-bef-link {
    a {
      @include media($breakpoint-1) {
        width: 26px;
        background-size: contain;
      }
      font-size: 0;
      background: url('../img/arrow.svg') no-repeat center right;
      display: block;
      width: 15px;
      height: 13px;
      background-size: contain;
    }
  }
  .form-item-edit-sort-order-desc {
    a {
      transform: rotate(180deg);
    }
  }
}