.flexslider {
  position: relative;
}
.flex-direction-nav {
  li {
    list-style: none;
    a {
      font-size: 0;
      position: absolute;
      top: 50%;
      margin-top: -15px;
      width: 20px;
      height: 30px;
      background: url('../img/arrow--blue.svg') no-repeat center center;
      background-size: contain;
      &.flex-prev {
        left: 10px;
        transform: rotate(180deg);
      }
      &.flex-next {
        right: 10px;
      }
    }
  }
}