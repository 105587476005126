.banner {
  $mobile-height: 240px;
  @include media($breakpoint-2) {
    margin: 0 0 40px 0;
  }
  @include media($breakpoint-3) {
    margin: 0 0 85px 0;
  }
  margin: 0 0 20px 0;
  &__bg {
    @include media($menu-switch) {
      background-size: cover;
      height: 580px;
      padding: 0;
      background-position: center center;
    }
    background-size: auto $mobile-height;
    padding-top: $mobile-height;
    background-repeat: no-repeat;
    background-position: center center;
  }
  &__text {
    @include media($menu-switch) {
      padding: 85px 0;
      text-shadow: 0 2px 5px rgba(0, 0, 0, .65);
      max-width: 670px;
      text-align: left;
      margin: 0;
      background: none;
    }

    background: $primary;
    padding: 27px 15px;
    margin-left: -15px;
    margin-right: -15px;
    text-align: center;
  }
  &__title {
    @include media($menu-switch) {
      font-size: 60px;
      margin: 0 0 10px 0;
      text-align: left;
    }
    text-align: center;
    margin: 0;
    border: 0;
    color: $white;
    font-size: 20px;
    line-height: 1.25;
  }
  &__subtitle {
    @include media($menu-switch) {
      font-size: 24px;
    }
    color: $white;
    text-transform: none;
    font-family: $title-font-family;
    margin: 0;
    padding: 0;
  }
}