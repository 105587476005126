.field-sidebar {
  @include media($breakpoint-2) {
    text-align: left;
  }
  text-align: center;
  h2 {
    border-bottom: 1px solid $grey-blue;
    font-size: 16px;
    color: $color;
    text-transform: none;
    margin: 0 0 10px;
  }
}