.node {
  $this: &;
  &__link {
    position: relative;
    display: block;
    text-decoration: none;
    color: $black;
    border: 1px solid transparent;
    padding: 0 0 20px 0;
    transform: scale(1);
    transition: all 200ms linear;
    &:hover {
      color: $black;
      transform: scale(1.1);
    }
  }
  &.node-teaser {
    #{$this}__link {
      &:hover {
        border: 1px solid $grey-blue;
        transform: scale(1.025);
      }
    }
  }
}