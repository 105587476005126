.block-menu-menu-social,
.menu-social {
  ul.menu {
    li {
      display: inline-block !important;
      margin: 0 6px;
      a {
        display: block;
        width: 30px;
        height: 30px;
        border: 2px solid $white;
        color: $white;
        font-size: 0;
        border-radius: 50%;
        text-align: center;
        padding: 4px;
        &::before {
          font-size: 16px;
          color: $white;
        }
      }
    }
  }
}