html {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: $base-text-color;
  font-family: $base-font-family;

  ::selection {
    background: $base-selected-text-background-color;
    color: $base-selected-text-color;
  }
  ::-moz-selection {
    background: $base-selected-text-background-color;
    color: $base-selected-text-color;
  }
}

body {
  line-height: $base-line-height;
  font-size: $base-font-size;
}

// Base link settings
a {
  transition: all 100ms linear;
  color: $base-link-color;
  outline: 0 !important;
  &:hover,
  &:focus {
    color: $base-link-hover-color;
  }
}

// Text
p {
  margin: 0 0 20px;
}

strong {
  font-weight: $bold;
}

// Headers
h1 {
  @include media($breakpoint-1) {
    font-size: 28px;
    text-align: left;
    margin: 0 0 35px;
  }
  @include media($breakpoint-5) {
    margin: 0 0 70px;
  }
  font-size: 20px;
  text-align: center;
  color: $black;
  font-family: $title-font-family;
  margin: 0 0 20px;
  border-bottom: 1px solid $grey-blue;
  padding-bottom: 8px;
  line-height: 1;
}

h2 {
  @include media($breakpoint-1) {
    font-size: 20px;
  }
  line-height: 1;
  color: $black;
  margin: 0 0 15px;
  font-family: $base-font-family;
  font-size: 16px;
  text-transform: uppercase;
  padding-bottom: 10px;
  &.primary {
    padding: 13px 20px;
    margin: 0 0 40px 0;
    background: $primary-light;
  }
}

h3 {
  font-family: $base-font-family;
  font-weight: $bold;
  font-size: $base-font-size;
  color: $black;
  text-transform: uppercase;
  margin: 0 0 10px;
}

h4 {
  margin: 0 0 10px;
}

h5 {
  margin: 0 0 10px;
}

h6 {
  margin: 0 0 10px;
}

.element-invisible {
  display: none;
}