// Class is added for specific form elements when placeholders are supported.
// See js-label.js.
.js-label {
  transition: all 100ms;
  position: relative;
  margin-top: 15px;
  label {
    transition: all 100ms linear;
    opacity: 1;
    top: -27px;
    position: absolute;
  }
  &.js-unhighlight-label {
    label {
      opacity: .6;
    }
  }
  &.js-hide-label {
    margin-top: 0;
    label {
      opacity: 0;
    }
  }
}
