.breadcrumb {
  @include media($breakpoint-2) {
    display: block;
    margin-top: 20px;
    font-size: 14px;
    color: $black;
  }
  display: none;
  a {
    color: $black;
    text-decoration: none;
  }
}