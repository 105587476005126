.bean-basic-block  {
  text-align: center;
  border: 1px solid $grey-middle;
  background: url('../img/pattern.svg') center center;
  font-size: 18px;
  &__inner {
    @include media($breakpoint-2) {
      margin: 80px 170px;
      padding: 25px 45px;
    }
    background: $white;
    margin: 40px;
    padding: 25px;
  }
  h2 {
    color: $color;
    font-family: 'angleciap-bold';
    text-transform: uppercase;
    font-size: 30px;
    padding-bottom: 7px;
  }
  p {
    margin: 0;
  }
}