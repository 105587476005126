.top-nav {
  @include media($menu-switch) {
    display: block;
    background: $grey;
    ul.menu {
      text-align: center;
      li {
        display: inline-block;
        margin: 0 19px;
        a {
          color: $black;
          display: block;
          font-weight: $semibold;
          font-size: 14px;
          text-decoration: none;
          padding: 12px 0 9px 0;
          border-bottom: 4px solid transparent;
          &.active-trail,
          &.active,
          &:hover {
            border-bottom: 4px solid $primary;
          }
        }
      }
    }
  }
  display: none;
}