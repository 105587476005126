.grid {
  @include outer-container();
}

.grid__item {
  margin-bottom: flex-gutter($grid-columns);
}

.grid__item--2 {
  @include media($breakpoint-2) {
    @include span-columns(6 of 12);
    @include omega(2n);
  }
}

.grid__item--3 {
  @include media($breakpoint-1) {
    @include span-columns(6 of 12);
    @include omega(2n);
  }

  @include media($breakpoint-2) {
    @include omega-reset(2n);
    @include span-columns(4 of 12);
    @include omega(3n);
  }
  @include span-columns(12 of 12);
}

.grid__item--4 {
  @include media($breakpoint-1) {
    @include span-columns(6 of 12);
    @include omega(2n);
  }

  @include media($breakpoint-2) {
    @include omega-reset(2n);
    @include span-columns(3 of 12);
    @include omega(4n);
  }
}

.grid__item--6 {
  @include media($breakpoint-1) {
    @include span-columns(4 of 12);
    @include omega-reset(2n);
    @include omega(3n);
  }

  @include media($breakpoint-3) {
    @include span-columns(2 of 12);
    @include omega-reset(3n);
    @include omega(6n);
  }
  @include span-columns(6 of 12);
  @include omega(2n);
}
