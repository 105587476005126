.ds-2col {
  .group-left {
    @include media($breakpoint-2) {
      @include span-columns(6 of 12);
    }
    @include span-columns(12 of 12);
    @include omega;
  }
  .group-right {
    @include media($breakpoint-2) {
      @include span-columns(6 of 12);
      @include omega;
    }
    @include span-columns(12 of 12);
    @include omega;
  }
}