.disclaimer {
  @include media($breakpoint-1) {
    text-align: left;
  }
  background: $black;
  color: $white;
  padding: 20px 30px 40px;
  font-size: 14px;
  text-align: center;
  .gbl-logo {
    @include media($breakpoint-1) {
      float: right;
    }
  }
  > span {
    @include media($breakpoint-1) {
      display: inline;
    }
    display: block;
  }
}