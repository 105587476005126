.node-page {
  &.view-mode-full {
    @include media($breakpoint-2) {
      margin: 0 0 70px 0;
    }
    margin: 0 0 20px 0;
    .group-left {
      @include media($breakpoint-2) {
        @include span-columns(3 of 12);
      }
      @include span-columns(12 of 12);
      @include omega;
    }
    .group-right {
      @include media($breakpoint-2) {
        @include span-columns(9 of 12);
        @include omega;
      }
      @include span-columns(12 of 12);
      @include omega;
    }

    ul {
      padding-left: 20px;
      margin: 0 0 20px;
    }
  }
}