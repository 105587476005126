@mixin sidebar-title {
  position: relative;
  border-bottom: 1px solid $grey-blue;
  display: block;
  margin: 10px 0 0 0;
  &::after {
    content: '';
    background: url('../img/arrow.svg') no-repeat center;
    background-size: 18px auto;
    top: 0;
    right: 0;
    width: 18px;
    height: 18px;
    position: absolute;
  }
}

@mixin sidebar-title-reset {
  border: none !important;
  &::after {
    display: none !important;
  }
}

.sidebar {
  h2 {
    @include media($menu-switch) {
      margin-bottom: 38px;
      &::after {
        display: none;
      }
    }
    color: $color;
    font-family: $base-font-family;
    @include sidebar-title;

  }

  .is-open {
    ul.menu {
      display: block !important;
    }
    label::after,
    h2::after {
      transform: rotate(180deg);
    }
  }

  .block {
    .view-content > ul.menu,
    > ul.menu {
      @include media($menu-switch) {
        display: block ;
      }
      display: none;
      a {
        text-decoration: none;
        display: block;
        color: $color;
        &.active {
          color: $primary;
        }
      }
      li {
        list-style: none;
      }

      // Head title.
      > li {
        @include media($breakpoint-1) {
          margin: 0 0 35px 0;
        }
        margin: 0 0 20px 0;
        > a {
          @include media($breakpoint-2) {
            display: block;
            color: $black;
            font-family: $title-font-family;
            font-size: 21px;
            border-bottom: 1px solid $grey-blue;
            line-height: 1.15;
            padding: 0 0 8px 0;
            &:hover {
              color: $primary;
            }
          }
          display: none;
        }
      }
      ul.menu {
        @include media($breakpoint-1) {
          text-align: left;
        }
        background: $grey-light;
        padding: 10px 0;
        text-align: center;
        a {
          @include media($breakpoint-1) {
            padding-left: 30px;
          }
          padding: 6px 0;
          &:hover {
            color: $primary;
          }
          &.active,
          &.active-trail {
            font-weight: $bold;
            color: $primary;
          }
        }
      }
    }
  }

  .views-exposed-widget {
    > label {
      @include sidebar-title;
      padding-bottom: 10px;
    }
    .views-widget {
      display: none;
    }
    &.is-open {
      .views-widget {
        display: block;
      }
    }
  }
}

// Filter exceptions.
.views-widget-filter-field_pd_price_value,
.views-widget-filter-field_pd_price_value_1 {
  width: 48% !important;
  float: left;
  margin-right: 4%;
  label {
    @include sidebar-title-reset;
    font-size: 16px;
  }
  .views-widget {
    display: block !important;
  }
}
.views-widget-filter-field_pd_price_value_1 {
  margin-right: 0 !important;
}